define("discourse/plugins/discourse-calendar/discourse/initializers/add-event-button-initializer", ["exports", "discourse/lib/plugin-api"], function (_exports, _pluginApi) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    name: 'add-event-button-initializer',
    initialize(container) {
      const siteSettings = container.lookup("service:site-settings");
      if (siteSettings.discourse_post_event_enabled) {
        (0, _pluginApi.withPluginApi)('0.8.7', api => {
          api.modifyClass('component:add-event-button', {
            pluginId: 'discourse-calendar',
            init() {
              this._super(...arguments);
              this.api = api;
            }
          });
        });
      }
    }
  };
});