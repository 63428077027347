define("discourse/plugins/discourse-calendar/discourse/components/add-event-button", ["exports", "@ember/component", "@ember/object", "discourse/plugins/discourse-calendar/discourse/components/modal/post-event-builder", "discourse/plugins/discourse-calendar/discourse/widgets/discourse-post-event"], function (_exports, _component, _object, _postEventBuilder, _discoursePostEvent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AddEventButton extends _component.default {
    showAddEventModal() {
      const store = this.api.container.lookup('service:store');
      const modal = this.api.container.lookup('service:modal');
      const eventModel = store.createRecord("discourse-post-event-event");
      eventModel.setProperties({
        status: "public",
        custom_fields: _object.default.create({}),
        starts_at: moment(),
        timezone: moment.tz.guess()
      });
      console.log("Access calendar_categories_id:", this.siteSettings.calendar_categories_id);
      modal.show(_postEventBuilder.default, {
        model: {
          event: eventModel,
          api: this.api,
          updateCustomField: (field, value) => (0, _discoursePostEvent.updateCustomField)(eventModel, field, value),
          updateEventStatus: status => (0, _discoursePostEvent.updateEventStatus)(eventModel, status),
          updateEventRawInvitees: rawInvitees => (0, _discoursePostEvent.updateEventRawInvitees)(eventModel, rawInvitees),
          removeReminder: reminder => (0, _discoursePostEvent.removeReminder)(eventModel, reminder),
          addReminder: () => (0, _discoursePostEvent.addReminder)(eventModel),
          onChangeDates: changes => (0, _discoursePostEvent.onChangeDates)(eventModel, changes),
          updateTimezone: (newTz, startsAt, endsAt) => (0, _discoursePostEvent.updateTimezone)(eventModel, newTz, startsAt, endsAt)
        }
      });
    }
    static #_ = (() => dt7948.n(this.prototype, "showAddEventModal", [_object.action]))();
    didInsertElement() {
      super.didInsertElement(...arguments);
      const $container = $(".composer-fields .title-and-category");
      $container.addClass("show-event-controls");
      $(".composer-controls-event").appendTo($container);
      this.composerResized();
    }
    composerResized() {}
  }
  _exports.default = AddEventButton;
});